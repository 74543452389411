import { z } from 'zod'

/**
 * Roles known inside HOS.
 *
 * Updated by:
 * - XX.04.23 https://github.com/sidestream-tech/hanselmann-os/issues/128, file: "HoS_Permission_Proposal.pdf"
 * - 18.07.23 https://github.com/sidestream-tech/hanselmann-os/issues/609, file: "Rollen.xlsx"
 * - 28.08.23 https://github.com/sidestream-tech/hanselmann-os/issues/990, file: "HOS_Roles_28-08-2023" (Google Sheets)
 * - 29.11.23 https://github.com/sidestream-tech/hanselmann-os/pull/1745, for new external-access-feature
 */
export const roles = z.enum([
  // Not logged in
  'no-role',

  // Authenticated but no role
  'guest',

  // Authenticated with supreme priviledges
  'admin',

  /** Job-based roles */
  // Leading positions
  'project-manager', // "Projektmanager"
  'department-lead', // "Abteilungsleiter"

  'consignee', // "Warenempfang" / "Warenempfänger"
  'receptionist', // "Empfang"
  'rental-employee', // "Vermietung"
  'sales-employee', // "Verkauf"
  'disponent', // "Dispo" / "Disponent"
  'workshop-employee', // "Werkstatt"
  'marketing-employee', // "Marketing"
  'driver', // "Fahrer"
  'bookkeeping', // "Buchhaltung"
  'storage-lead', // "Lagerleiter"
  'storage-employee', // "Lager",
  'service-project-employee', // Dienstleistung
  'service-project-fitter', // Dienstleistung

  // Special roles for company Individuals
  'special-role-benni', // Added in https://github.com/sidestream-tech/hanselmann-os/issues/1340

  // People who freelance for hanselmann with the purpose of selling forklifts ("Manfred" & Co, "Außendienst Verkauf", "Freelancer Verkauf"). Added in https://github.com/sidestream-tech/hanselmann-os/pull/2069/
  'freelancer-sales',

  // Partner companies with a special relation to hanselmann that buy HOS machinery with special conditions to then re-sell it on their own terms without a connection to hanselmann anymore. Added in https://github.com/sidestream-tech/hanselmann-os/pull/2445
  'partner-sales',

  // Internal role for external-storage module
  // Added in https://github.com/sidestream-tech/hanselmann-os/issues/2315
  // Modified in https://github.com/sidestream-tech/hanselmann-os/issues/2610
  'logistics-employee',

  // Roles for external, non-hanselmann-employed entities
  'external-service-project-contractor',
  'external-customer',

  'ext-storage-user',

  // Role for customer-module
  // Added in https://github.com/sidestream-tech/hanselmann-os/issues/3031
  'customer-user',
])
export type UserRole = z.infer<typeof roles>

/**
 * External roles, i.e., users that are not hanselmann employees.
 *
 */
const _externalRoles = ['no-role', 'external-service-project-contractor', 'external-customer'] satisfies Partial<UserRole>[]

/**
 * Internal roles, i.e., users that are hanselmann employees but not guests
 */
export const internalRolesWithoutGuest = ['admin', 'project-manager', 'department-lead', 'consignee', 'receptionist', 'rental-employee', 'sales-employee', 'disponent', 'workshop-employee', 'marketing-employee', 'driver', 'bookkeeping', 'storage-lead', 'storage-employee', 'special-role-benni', 'service-project-employee', 'service-project-fitter', 'logistics-employee'] satisfies UserRole[]

/**
 * Internal roles, i.e., users that are hanselmann employees
 */
export const internalRoles = ['guest', ...internalRolesWithoutGuest] satisfies Partial<UserRole>[]

/**
 * Internal and contractor-roles, used to set rights for service-projects & tasks.
 */
export const internalAndContractorRoles = [...internalRoles, 'external-service-project-contractor'] satisfies Partial<UserRole>[]

/**
 * Roles allowed to create, edit and delete internal reservations
 */
export const internalReservationRoles = ['department-lead', 'project-manager', 'rental-employee', 'sales-employee', 'service-project-employee', 'service-project-fitter'] satisfies Partial<UserRole>[]

/**
 * Roles that can be invited to the system, these are people who should have access but are not part of the core-hanselmann organization.
 */
export const rolesThatCanBeInvitedToSystem = ['external-service-project-contractor', 'freelancer-sales', 'ext-storage-user', 'partner-sales', 'customer-user'] satisfies Partial<UserRole>[]

/**
 * Roles that have accesss to `UserTask`
 */
export const userTaskRoles = [...internalRoles, ...rolesThatCanBeInvitedToSystem] satisfies Partial<UserRole>[]

/**
 * Roles that have access to most or all data within the external storage module.
 * They can at least:
 * - switch `Customer`s when viewing data;
 * - access StoragePosition and Inbound/OutboundLogisticsTask pages;
 */
export const externalStorageInternalRoles: readonly UserRole[] = ['admin', 'department-lead', 'storage-lead', 'logistics-employee']

/**
 * All roles of external storage module (internal and external)
 */
export const externalStorageInternalAndExternalRoles: readonly UserRole[] = externalStorageInternalRoles.concat(['ext-storage-user'])

// TODO: rename it depending on the new module name
export const customerModuleRoles: readonly UserRole[] = ['customer-user', 'partner-sales']
